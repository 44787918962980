import React, { useMemo } from "react";
import { useMediaQuery, Typography } from "@mui/material";
import Article from "../Article/Article";
import useIsMobile from "../../../../hooks/useIsMobile";
import "./menupart.scss";

const isEven = (num) => num % 2 === 0;
function MenuPart({ menupart, index }) {
  const isMobile = useIsMobile();

  const containerClassName = useMemo(() => {
    return !isEven(index) || isMobile
      ? "menupart-container"
      : "menupart-container-reverse";
  }, [index, isMobile]);

  const contentClassName = useMemo(() => {
    return isMobile ? "menupart-content" : "menupart-ComputerContent";
  }, [isMobile]);

  const sortedMeals = useMemo(() => {
    return [...menupart.meals].sort((a, b) => a.id - b.id);
  }, [menupart.meals]);
  return (
    <section className="menupart">
      <Typography variant="h4" className="menupart-title">
        {menupart.name}
      </Typography>
      <div className={containerClassName}>
        {menupart.description && <p>{menupart.description}</p>}
        {menupart.photos[0] && (
          <img
            src={menupart.photos[0].url}
            alt={menupart.photos[0].title}
            className="menupart-photo"
          />
        )}
        <div className={contentClassName}>
          {sortedMeals.map((meal) => (
            <Article
              key={meal.id}
              title={meal.title}
              quarterprice={meal.quarterprice}
              halfprice={meal.halfprice}
              literprice={meal.literprice}
              price={meal.price}
              description={meal.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default React.memo(MenuPart);
