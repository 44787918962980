import { Box } from "@mui/material";
import ReactPlayer from "react-player";

export function VideoPlayer({ isMobile }) {
  return (
    <Box
      sx={{
        margin: "auto",
        width: "80%",
        border: `8px solid rgba(0, 0, 0, 0.9)`,
        borderRadius: "10px",
        boxShadow: "0px 22px 6px -7px rgba(0, 0, 0, 0.74)",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        <ReactPlayer
          url="/maelys_video.mp4"
          playing={!isMobile}
          loop={!isMobile}
          muted
          controls
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  );
}
