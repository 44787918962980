import { Helmet } from "react-helmet-async";

function ConfPolSEOMetadata({ siteUrl }) {
  return (
    <Helmet>
      <title>Politique de confidentialité | Maëly's Café</title>
      <meta
        name="description"
        content="Découvrez la politique de confidentialité appliquée sur le site web du bar Maëly's Café. Informations sur la collecte et l'utilisation de vos données personnelles."
      />
      <link rel="canonical" href={`${siteUrl}/politiques-confidentialite`} />
      <meta name="robots" content="index, follow" />
      <meta
        property="og:title"
        content="Politique de confidentialité | Maëly's Café"
      />
      <meta
        property="og:description"
        content="Découvrez comment Maëly's Café protège vos données personnelles et respecte votre vie privée."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`${siteUrl}/politiques-confidentialite`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Politique de confidentialité | Maëly's Café"
      />
      <meta
        name="twitter:description"
        content="Découvrez comment Maëly's Café protège vos données personnelles et respecte votre vie privée."
      />
    </Helmet>
  );
}

export default ConfPolSEOMetadata;
