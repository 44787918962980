import { useState, useEffect } from "react";
import { requestMenu } from "../utils/requests/requests";

const useMenu = (menuId) => {
  const [card, setCard] = useState([]);

  useEffect(() => {
    const getAllMenus = async () => {
      const result = await requestMenu(menuId);
      setCard(result);
    };

    getAllMenus();
  }, [menuId]);

  return { card };
};

export default useMenu;
