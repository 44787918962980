import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useAdminNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showReturnButton, setReturnButton] = useState(false);

  useEffect(() => {
    setReturnButton(location.pathname !== "/admin/home");
  }, [location]);

  const handleReturnClick = () => {
    if (location.pathname === "/login") {
      navigate("/", { replace: true });
    } else {
      navigate("/admin/home", { replace: true });
    }
  };

  return { showReturnButton, handleReturnClick, location };
};
