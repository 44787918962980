import React from "react";
import { Helmet } from "react-helmet-async";

function CartePageSEOMetadata({ isFoodCard, siteUrl }) {
  const title = isFoodCard
    ? "Notre Carte | Maëly's Café"
    : "Nos Boissons | Maëly's Café";
  const description = isFoodCard
    ? "Découvrez la carte du Maëly's Café, votre bar convivial à Six-Fours-Les-Plages. Savourez nos plats du jour, tapas et snacks, parfaits pour accompagner vos boissons et créer une ambiance chaleureuse."
    : "Explorez la carte des boissons du Maëly's Café, le bar incontournable de Six-Fours-Les-Plages. Large sélection de bières, cocktails, vins et softs pour tous les goûts. L'endroit idéal pour vos soirées entre amis.";
  const path = isFoodCard ? "/card" : "/drinks";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={`${siteUrl}${path}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${siteUrl}${path}`} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
}

export default React.memo(CartePageSEOMetadata);
