import React, { useMemo } from "react";
import { Box, useMediaQuery, Typography } from "@mui/material";
import "./article.scss";
import useIsMobile from "../../../../hooks/useIsMobile";

function PriceDisplay({ label, value }) {
  return (
    <div className="article-item__price-container-double-price--price">
      <Typography variant="subtitle1">{label}</Typography>
      <Typography variant="h3">{value} €</Typography>
    </div>
  );
}

function Article({ title, price, quarterprice, halfprice, description }) {
  const isMobile = useIsMobile();

  const boxStyles = useMemo(
    () => ({
      flex: 4,
      margin: isMobile ? 0 : "0.5rem",
      padding: isMobile ? 0 : "0 0.5rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: description ? "flex-start" : "flex-end",
    }),
    [isMobile, description]
  );

  const renderPrice = () => {
    if (!halfprice) {
      return <Typography variant="h3">{price} €</Typography>;
    }
    return (
      <div className="article-item__price-container-double-price">
        {quarterprice && <PriceDisplay label="25cl" value={quarterprice} />}
        {halfprice && <PriceDisplay label="50cl" value={halfprice} />}
      </div>
    );
  };
  return (
    <Box sx={boxStyles}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: "70%",
        }}
      >
        <Typography variant="h3">{title}</Typography>
        {description && (
          <Typography variant="subtitle1">{description}</Typography>
        )}
      </Box>
      <div className="article-item__price-container">{renderPrice()}</div>
    </Box>
  );
}

export default React.memo(Article);
