import React, { lazy, Suspense } from "react";
import Header from "./Header/Header";
import Nav from "./Nav/Nav";
import useIsMobile from "../../../hooks/useIsMobile";
import DesktopOnly from "../DesktopOnly";
import "./sideBar.scss";

const Infos = lazy(() => import("./Infos/Infos"));
const Footer = lazy(() => import("./Footer/Footer"));
function Sidebar() {
  const isMobile = useIsMobile();

  return (
    <div className="sidebar__container">
      <Header />
      <Nav />
      <Suspense fallback={<div>Chargement...</div>}>
        <DesktopOnly>
          <Infos />
          <Footer />
        </DesktopOnly>
      </Suspense>
    </div>
  );
}

export default Sidebar;
