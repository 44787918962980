import { styled, Button } from "@mui/material";

export const ReturnButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "0",
  left: "0",
  margin: "1rem",
  padding: "1rem",
  backgroundColor: "transparent",
  color: "#fffde7",
  fontFamily: "Dosis",
  fontSize: "1.5rem",
  fontWeight: "bold",
  alignItems: "center",
  "&:hover": {
    textShadow:
      "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
  },
}));

export const DisconnectButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "0",
  right: "0",
  margin: "1.5rem",
  padding: "0.5rem",
  color: "#fffde7",
  fontFamily: "Dosis",
  fontSize: "1rem",
  fontWeight: "bold",
  backgroundColor: theme.palette.error.main,
  backdropFilter: "blur(4px)",
  zIndex: 100,
  "&:hover": {
    transform: "scale(1.2)",
  },
}));
