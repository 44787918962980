import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
  TableContainer,
  Paper,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import moment from "moment";
import { getFrFormat } from "../../../../utils/dates/dates";
import ReservationTable from "./ReservationTable";

const BACKGROUND_COLOR = "rgba(94, 94, 94, 0.865)";

function ResaAccordion({
  date,
  expanded,
  handleChange,
  reservations,
  isMobile,
}) {
  const totalResa = reservations.reduce((acc, resa) => acc + resa.seat, 0);

  return (
    <Accordion
      disabled={reservations.length === 0}
      expanded={expanded === date}
      onChange={handleChange(date)}
      key={date}
      sx={{
        color: "#fffde7",
        backgroundColor: "transparent",
        "& .Mui-expanded": {
          backgroundColor: BACKGROUND_COLOR,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: "#fffde7" }} />}
        sx={{
          justifyContent: "space-between",
          "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
          },
          "& .Mui-expanded": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Stack direction={isMobile ? "column" : "row"} spacing={2}>
          <Typography variant="h3">
            {getFrFormat(date)} - total resa: {totalResa}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{ backgroundColor: BACKGROUND_COLOR, padding: "1rem 0" }}
      >
        <Stack direction={isMobile ? "column" : "row"}>
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <ReservationTable reservations={reservations} isMobile={isMobile} />
          </TableContainer>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export default ResaAccordion;
