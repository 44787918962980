import { useState, useEffect, useCallback } from "react";
import { Box, styled, keyframes, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import BookingForm from "../../../UserComponents/BookingForm/CreateResa";
import { getAllEvents } from "../../../../utils/requests/eventRequest";
import "./modalmenu.scss";

const NAVIGATION_LINKS = [
  { to: "/", label: "Accueil" },
  { to: "/drinks", label: "Nos Boissons" },
  { to: "/events", label: "Evènements et concerts" },
  { to: "/contact", label: "Nous contacter" },
];
const slideIn = keyframes`
  from { transform: translateX(100%); visibility: hidden; }
  to { transform: translateX(0); visibility: visible; }
`;

const slideOut = keyframes`
  from { transform: translateX(0); visibility: visible; }
  to { transform: translateX(100%); visibility: hidden; }
`;

const ModalBox = styled(Box)(({ isVisible }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 5,
  backdropFilter: "blur(5px)",
  animation: `${isVisible ? slideOut : slideIn} 0.5s ease forwards`,
}));

const buttonStyle = {
  mt: "2rem",
  backgroundColor: "#FFFDDE",
  color: "#333333",
  border: "none",
  borderRadius: "10px",
  padding: "1rem 2rem",
  boxShadow:
    "0px 0px 6px rgba(255,253,231,0.6), inset 0px 0px 6px rgba(255,253,231,0.6), 0px 0px 16px rgba(255,253,231,0.4), inset 0px 0px 16px rgba(255,253,231,0.4), 0px 0px 32px rgba(255,253,231,0.2), inset 0px 0px 32px rgba(255,253,231,0.2)",
};

const useModalVisibility = (buttonOpen, modalElement) => {
  const [isVisible, setIsVisible] = useState(false);

  const open = useCallback(() => {
    modalElement.current.style.display = "block";
    setIsVisible(true);
  }, [modalElement]);

  const close = useCallback(() => {
    setIsVisible(false);
    modalElement.current.style.display = "none";
  }, [modalElement]);

  useEffect(() => {
    buttonOpen.current.onclick = open;
  }, [buttonOpen, open]);

  return { isVisible, open, close };
};

const useEvents = () => {
  const [events, setEvents] = useState();
  const [openModal, setOpenModal] = useState(false);

  const fetchEvents = useCallback(async () => {
    const response = await getAllEvents();
    setEvents(response.data);
  }, []);

  const handleClickResa = useCallback(async () => {
    await fetchEvents();
    setOpenModal(true);
  }, [fetchEvents]);

  return { events, openModal, setOpenModal, handleClickResa };
};

function NavigationLink({ to, label }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `nav__mobile-container-link ${
          isActive ? "nav__mobile-container-link--active" : ""
        }`
      }
    >
      <p>{label}</p>
    </NavLink>
  );
}

function ModalMenu({ buttonOpen, modalElement }) {
  const { isVisible, close } = useModalVisibility(buttonOpen, modalElement);
  const { events, openModal, setOpenModal, handleClickResa } = useEvents();
  return (
    <ModalBox className="modal-overlay" onClick={close} ref={modalElement}>
      <nav className="nav__mobile-container">
        {NAVIGATION_LINKS.map((link) => (
          <NavigationLink key={link.to} {...link} />
        ))}
        <Button sx={buttonStyle} onClick={handleClickResa}>
          Réserver en ligne
        </Button>
        <BookingForm
          open={openModal}
          events={events}
          onClose={() => setOpenModal(false)}
        />
      </nav>
    </ModalBox>
  );
}

export default ModalMenu;
