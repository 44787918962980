import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/fr";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actionOpenSnackbar } from "../../../../redux/actions";
import {
  getFrFormat,
  getTableHour,
  getTime,
} from "../../../../utils/dates/dates";
import {
  createReservation,
  getAvailableSeats,
} from "../../../../utils/requests/reservationRequest";
import { getBookingSchema } from "../../../../utils/schemas";

function BookingForm({
  open,
  onClose,
  eventDate,
  eventTitle,
  eventId,
  events,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [availableSeats, setAvailableSeats] = useState(0);
  const [resaAvailable, setResaAvailable] = useState(true);

  const [dateResa, setDateResa] = useState(eventDate || null);

  const bookingSchema = getBookingSchema(availableSeats);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(bookingSchema),
    mode: "onTouched",
    defaultValues: {
      date: dateResa || null,
    },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const fetchAvailableSeats = async (date) => {
    setLoading(true);
    const response = await getAvailableSeats(date);
    if (response.status === 200) {
      setAvailableSeats(response.data);
    }
    setLoading(false);
  };

  const handleAvailable = (date) => {
    const eventForDate = events.find((event) => getTime(event.date) === date);
    if (eventForDate) {
      setResaAvailable(!eventForDate.complete);
    }
  };

  useEffect(() => {
    if (open && dateResa !== null) fetchAvailableSeats(dateResa);
  }, [open, dateResa]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (eventId) {
        setValue("date", dateResa);
      }
      const response = await createReservation(data);
      if (response.status === 200) {
        dispatch(
          actionOpenSnackbar({
            severity: "success",
            alertMessage:
              "Réservation enregistrée avec succès! Un email de confirmation a été envoyé à l'adresse email renseignée.",
          })
        );
        handleClose();
      }
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          severity: "error",
          alertMessage: error.message || "Une erreur s'est produite",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(5px)",
          },
        },
      }}
      PaperProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.8)",
          backdropFilter: "blur(5px)",
          position: "relative",
          border: `8px solid #fffde7`,
          borderRadius: "5px",
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <Box>
        <Typography variant="h4">Réservation</Typography>
        {eventDate && (
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              marginBottom: "1rem",
              "& span": {
                fontWeight: 700,
              },
            }}
          >
            Pour le <span>{getFrFormat(eventDate)}</span>
          </Typography>
        )}
        {availableSeats && (
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "700",
              my: "1rem",
              mx: "auto",
              width: "100%",
              textAlign: "center",
            }}
          >
            Places disponibles : <strong>{availableSeats}</strong>
          </Typography>
        )}
      </Box>
      <IconButton
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: "#fffde7",
        })}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ color: "#fffde7" }}>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ gap: 2 }}
        >
          {!eventDate && (
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="fr"
            >
              <Controller
                name="date"
                control={control}
                defaultValue={null}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="Date de la réservation"
                    value={value ? moment(value) : null}
                    minDate={moment("2025-01-07")}
                    onChange={(date) => {
                      const isAvailable = true;
                      const goodDate = getTime(date);
                      handleAvailable(goodDate);
                      setDateResa(goodDate);
                      setValue("date", goodDate || null);
                      onChange(goodDate || null);
                    }}
                    inputFormat="DD-MM-YYYY"
                    disablePast
                    slotProps={{
                      day: {
                        sx: {
                          color: "#fffde7",
                        },
                      },
                      openPickerIcon: {
                        sx: { color: "#fffde7" },
                      },
                      layout: {
                        sx: {
                          background: "rgba(0, 0, 0, 0.8)",
                        },
                      },
                      textField: {
                        color: "#fffde7",
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message || "",
                        size: "small",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          )}
          {!resaAvailable && (
            <Typography variant="subtitle2" sx={{ color: "red" }}>
              Les réservations sont complètes pour cette date.
            </Typography>
          )}
          <TextField
            color="#fffde7"
            label="Prénom"
            type="text"
            placeholder="Prénom"
            error={Boolean(errors.firstname)}
            helperText={errors.firstname?.message}
            {...register("firstname")}
          />
          <TextField
            color="#fffde7"
            label="Nom"
            type="text"
            placeholder="Nom"
            error={Boolean(errors.lastname)}
            helperText={errors.lastname?.message}
            {...register("lastname")}
          />
          <TextField
            color="#fffde7"
            label="Email"
            type="text"
            placeholder="Email"
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            {...register("email")}
          />
          <TextField
            color="#fffde7"
            label="Numéro de téléphone"
            type="tel"
            placeholder="0612345678"
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message}
            {...register("phone")}
          />
          <TextField
            color="#fffde7"
            label="Nombre de personnes"
            type="number"
            placeholder="Nombre de personnes"
            error={Boolean(errors.seat)}
            helperText={errors.seat?.message}
            {...register("seat")}
          />
          <Typography variant="subtitle2">{getTableHour(dateResa)}</Typography>
          <Typography variant="subtitle2">
            ** Vos informations personnelles ne seront jamais diffusées et
            seront supprimées le lendemain de la date de réservation.
          </Typography>
          <Button
            disabled={loading || !resaAvailable}
            type="submit"
            sx={{
              color: "#fffde7",
              backgroundColor: "rgba(255,253,231,0.2)",
              "&.Mui-disabled": {
                backgroundColor: "#666",
                color: "#333333",
                border: "none",
                cursor: "not-allowed",
                boxShadow: "none",
              },
              "&:hover": {
                textShadow:
                  "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
              },
            }}
          >
            Réserver
          </Button>
        </Stack>
        <Backdrop
          open={loading}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <CircularProgress color="#fffde7" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}

export default BookingForm;
