import React from "react";
import { Helmet } from "react-helmet-async";

function ContactPageSEOMetadata({ siteName, siteDescription, contactUrl }) {
  return (
    <Helmet>
      <title>{`Contactez-nous | ${siteName}`}</title>
      <meta
        name="description"
        content={`${siteDescription} Appelez-nous au 09 50 80 87 15 ou écrivez-nous à contact@maelys-cafe.fr pour toute information ou réservation.`}
      />
      <link rel="canonical" href={contactUrl} />
      <meta name="robots" content="index, follow" />
      <meta
        property="og:title"
        content={`Contactez-nous | ${siteName} - Bar à Six-Fours-les-Plages`}
      />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={contactUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={`Contactez-nous | ${siteName} - Bar à Six-Fours-les-Plages`}
      />
      <meta name="twitter:description" content={siteDescription} />
    </Helmet>
  );
}

export default React.memo(ContactPageSEOMetadata);
