import React from "react";
import { Helmet } from "react-helmet-async";
import AdminMain from "../AdminMain/AdminMain";
import { ResaPageContent } from "./ResaPageContent";

function ResaPage() {
  return (
    <>
      <Helmet>
        <title>Admin - Réservation</title>
        <meta name="robots" content="noindex, nofollow, noarchive" />
      </Helmet>
      <AdminMain>
        <ResaPageContent />
      </AdminMain>
    </>
  );
}

export default ResaPage;
