import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { actionAppMount } from "../redux/actions";
import App from "../components/App/App";

function AppContainer() {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(actionAppMount());
  }, [dispatch]);

  return <App />;
}

export default AppContainer;
