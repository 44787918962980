import { Box, useMediaQuery } from "@mui/material";
import Main from "../../common/Main/Main";
import HomePageEvents from "../EventConcertPage/HomePageEvents";
import { HomePageSEOMetadata } from "./HomePageSEOMetadata";
import { ImageSlider } from "./ImageSlider";
import { VideoPlayer } from "./VideoPlayer";
import useIsMobile from "../../../hooks/useIsMobile";

function HomePage() {
  const isMobile = useIsMobile();

  return (
    <>
      <HomePageSEOMetadata />
      <Main>
        <Box>
          <ImageSlider isMobile={isMobile} />
          <VideoPlayer isMobile={isMobile} />
          <HomePageEvents />
        </Box>
      </Main>
    </>
  );
}

export default HomePage;
