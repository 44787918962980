import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ResaAccordion from "./ResaAccordion";
import useIsMobile from "../../../hooks/useIsMobile";
import { useReservations } from "../../../hooks/useReservations";

export function ResaPageContent() {
  const isMobile = useIsMobile();
  const reservations = useReservations();
  const [expanded, setExpanded] = useState();

  return (
    <Box>
      <Typography variant="h2">Gestion des Réservations</Typography>
      <Box
        sx={{
          width: isMobile ? "95%" : "60%",
          margin: "auto",
          padding: "1.5rem",
          backgroundColor: "rgba(17, 17, 17, 0.865)",
          backdropFilter: "blur(4px)",
          borderRadius: "5px",
        }}
      >
        {reservations
          ?.sort((a, b) => new Date(a.date) - new Date(b.date))
          .map((r) => (
            <ResaAccordion
              key={r.date}
              date={r.date}
              expanded={expanded}
              handleChange={(panel) => (_, isExpanded) =>
                setExpanded(isExpanded ? panel : false)}
              reservations={r.reservations}
              isMobile={isMobile}
            />
          ))}
      </Box>
    </Box>
  );
}
