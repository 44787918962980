import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import AppContainer from "./container/AppContainer";
import store from "./redux/store";
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import theme from "./Theme";

function Root() {
  return (
    <Provider store={store}>
      <BrowserRouter
        future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
      >
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <HelmetProvider>
              <ScrollToTop />
              <AppContainer />
            </HelmetProvider>
          </ThemeProvider>
        </React.StrictMode>
      </BrowserRouter>
    </Provider>
  );
}

export default Root;
