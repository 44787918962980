import { Route, Routes } from "react-router-dom";
import HomePage from "../UserComponents/HomePage/HomePage";
import EventConcertPage from "../UserComponents/EventConcertPage/EventConcertPage";
import ContactPage from "../UserComponents/ContactPage/ContactPage";
import ConfPage from "../UserComponents/ConfPol/ConfPol";
import NotFoundPage from "../common/NotFoundPage/NotFoundPage";
import RequireAuth from "../AdminComponents/RequireAuth/RequireAuth";
import LoginPage from "../AdminComponents/LoginPage/LoginPage";
import AdminHomePage from "../AdminComponents/AdminHomePage/AdminHomePage";
import ResaPage from "../AdminComponents/ResaPage";
import EventPage from "../AdminComponents/EventPage/EventPage";
import MenuPage from "../AdminComponents/MenuPage/MenuPage";
import CardContainer from "../../container/CardContainer";
import CartePage from "../UserComponents/CartePage/CartePage";
import UpdateResaPage from "../UserComponents/BookingForm/UpdateResaPage";
import DeleteResaPage from "../UserComponents/BookingForm/DeleteResaPage";

const publicRoutes = [
  { path: "/", element: <HomePage /> },
  { path: "drinks", element: <CartePage cardType="drink" /> },
  { path: "events", element: <EventConcertPage /> },
  { path: "contact", element: <ContactPage /> },
  { path: "update-reservation/:reservationId", element: <UpdateResaPage /> },
  { path: "delete-reservation/:reservationId", element: <DeleteResaPage /> },
  { path: "politiques-confidentialite", element: <ConfPage /> },
  { path: "login", element: <LoginPage /> },
];

const adminRoutes = [
  { path: "home", element: <AdminHomePage /> },
  { path: "event", element: <EventPage /> },
  { path: "reservation", element: <ResaPage /> },
  {
    path: "menus",
    element: <MenuPage />,
    children: [{ path: "drink", element: <CardContainer /> }],
  },
];

function App() {
  return (
    <Routes>
      {publicRoutes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path="admin" element={<RequireAuth />}>
        {adminRoutes.map(({ path, element, children }) => (
          <Route key={path} path={path} element={element}>
            {children &&
              children.map(({ path: childPath, element: childElement }) => (
                <Route
                  key={childPath}
                  path={childPath}
                  element={childElement}
                />
              ))}
          </Route>
        ))}
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
