import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MobileStepper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ArticleEvent from "./ArticleEvent";
import { getEvents } from "./const";
import useIsMobile from "../../../hooks/useIsMobile";

const CustomStepperStyle = styled(MobileStepper)`
  background-color: transparent;
  color: #fff;
  & .MuiMobileStepper-dotActive {
    background-color: #fff;
  }
`;

function HomePageEvents() {
  const isMobile = useIsMobile();
  const [weekEvent, setWeekEvent] = useState([]);
  const [title, setTitle] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const maxSteps = weekEvent.length;

  const getWeekEvents = async () => {
    const resultEvent = await getEvents(true);
    setTitle(resultEvent.title);
    setWeekEvent(resultEvent.events);
  };

  useEffect(() => {
    getWeekEvents();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const renderEvents = () => {
    if (isMobile) {
      return (
        <Stack sx={{ width: "100%", height: "100%" }}>
          <SwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            slideStyle={{ padding: 10, width: "100%", height: "100%" }}
          >
            {weekEvent.map((item) => (
              <ArticleEvent
                key={item.id}
                id={item.id}
                image={item.photos[0] ? item.photos[0] : null}
                date={item.date}
                title={item.title}
                group={item.group}
                description={item.description}
                complete={item.complete}
              />
            ))}
          </SwipeableViews>
          <CustomStepperStyle
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                sx={{ color: "#fff", fontSize: "1rem", fontWeight: "bold" }}
              >
                Suiv.
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{ color: "#fff", fontSize: "1rem", fontWeight: "bold" }}
              >
                <KeyboardArrowLeft />
                Préc.
              </Button>
            }
          />
        </Stack>
      );
    }
    return (
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {weekEvent.map((item) => (
          <ArticleEvent
            image={item.photos ? item.photos[0] : null}
            date={item.date}
            title={item.title}
            group={item.group}
            description={item.description}
            complete={item.complete}
            key={item.id}
            id={item.id}
          />
        ))}
      </Stack>
    );
  };
  return (
    <Box sx={{ my: "1rem" }}>
      <Typography variant="h2">{title}</Typography>
      {weekEvent.length > 0 && renderEvents()}
    </Box>
  );
}

export default HomePageEvents;
