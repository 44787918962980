import { KeyboardReturn } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAdminNavigation } from "../../../hooks/useAdminNavigation";
import useIsMobile from "../../../hooks/useIsMobile";
import { actionCloseSnackbar, actionLogout } from "../../../redux/actions";
import SnackbarComponent from "../../common/SnackbarComponent";
import { DisconnectButton, ReturnButton } from "./StyledComponents";

import "./adminmain.scss";

function AdminTitle({ isMobile }) {
  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: "3rem",
        marginTop: isMobile ? "5rem" : "1rem",
      }}
    >
      Page Admin
    </Typography>
  );
}

function Main({ children }) {
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.login.isLogged);
  const { open, onClose, severity, alertMessage } = useSelector(
    (state) => state.message
  );
  const isMobile = useIsMobile();
  const { showReturnButton, handleReturnClick, location } =
    useAdminNavigation();

  const handleCloseSnackbar = () => {
    dispatch(actionCloseSnackbar());
  };

  const handleClickDisconnect = () => {
    dispatch(actionLogout(false));
  };

  return (
    <main className="admin__main">
      <Box>
        {showReturnButton && (
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -30],
                    },
                  },
                ],
              },
            }}
            title={
              location.pathname === "/login"
                ? "Retourner à la page d'accueil"
                : "Retourner au menu principal"
            }
          >
            <ReturnButton
              onClick={handleReturnClick}
              startIcon={<KeyboardReturn style={{ fontSize: "1.5rem" }} />}
            >
              Retour
            </ReturnButton>
          </Tooltip>
        )}
        <AdminTitle isMobile={isMobile} />
        {isLogged && (
          <DisconnectButton onClick={handleClickDisconnect}>
            Déconnexion
          </DisconnectButton>
        )}
      </Box>
      {children}
      <SnackbarComponent
        open={open}
        onClose={handleCloseSnackbar}
        severity={severity}
        alertMessage={alertMessage}
      />
    </main>
  );
}

export default Main;
