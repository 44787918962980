import { Helmet } from "react-helmet-async";

export function HomePageSEOMetadata() {
  return (
    <Helmet>
      <title>Accueil | Maëly's Café</title>
      <meta
        name="description"
        content="Bienvenue au Maëly's Café, votre bar convivial à Six-Fours-les-Plages. Découvrez nos menus du jour, nos événements de la semaine et réservez votre table en ligne. Ambiance chaleureuse et concerts live."
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="Maëly's Café, bar, événements, concerts, Six-Fours-les-Plages, menu du jour, réservation"
      />
      <meta
        property="og:title"
        content="Maëly's Café - Bar & Événements à Six-Fours-les-Plages"
      />
      <meta
        property="og:description"
        content="Découvrez le Maëly's Café : menus du jour, événements hebdomadaires, et ambiance unique à Six-Fours-les-Plages. Réservez maintenant !"
      />
      <meta property="og:url" content={process.env.REACT_APP_URL} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="canonical" href={process.env.REACT_APP_URL} />
      <script type="application/ld+json">
        {`
      {
        "@context": "http://schema.org",
        "@type": "Restaurant",
        "name": "Maëly's Café",
        "description": "Bar et café proposant des menus du jour et des événements hebdomadaires",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "chemin de Bassaquet",
          "addressLocality": "Six-Fours-les-Plages",
          "postalCode": "83140",
          "addressCountry": "FR"
        },
        "telephone": "0950808715",
        "url": "${process.env.REACT_APP_URL}",
        "inLanguage": "fr-FR"
      }
    `}
      </script>
    </Helmet>
  );
}
