import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Main from "../../common/Main/Main";
import EventSEOMetadata from "./EventSEOMetadata";
import EventList from "./EventList";
import { getEvents } from "./const";

function EventConcertPage() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const getAllEvents = async () => {
      const resultEvents = await getEvents();
      if (resultEvents.length > 0) setEvents(resultEvents);
    };

    getAllEvents();
  }, []);

  return (
    <>
      <EventSEOMetadata />
      <Main>
        <Typography variant="h2">Evénements et concerts</Typography>
        <EventList events={events} />
      </Main>
    </>
  );
}

export default EventConcertPage;
