import "./button.scss";

function Button({ title, type, classname, name, onClick, disabled }) {
  return (
    <button
      className={disabled ? "disabled" : `${classname}`}
      onClick={onClick}
      type={type}
      title={title}
      disabled={disabled}
    >
      {name}
    </button>
  );
}

export default Button;
