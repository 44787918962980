import { useState, useEffect, useCallback } from "react";

const useScrollToTop = (threshold = 150) => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  const handleShowButton = useCallback(() => {
    setShowTopBtn(window.scrollY > threshold);
  }, [threshold]);

  const goToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleShowButton);
    return () => window.removeEventListener("scroll", handleShowButton);
  }, [handleShowButton]);

  return { showTopBtn, goToTop };
};

export default useScrollToTop;
