import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Tooltip, Box, styled } from "@mui/material";
import AdminMain from "../AdminMain/AdminMain";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(6),
    padding: theme.spacing(2),
  },
}));

const ButtonLink = styled(Link)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  backdropFilter: "blur(5px)",
  border: "none",
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.white,
  fontSize: "2rem",
  width: "30%",
  fontWeight: 700,
  margin: theme.spacing(2, "auto"),
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const adminLinks = [
  {
    to: "/admin/menus/drink",
    title: "Gestion des Boissons",
    tooltip: "Gérer toute la partie menu et boisson",
  },
  {
    to: "/admin/event",
    title: "Gestion des Évènements",
    tooltip: "Ajouter, Modifier ou Supprimer un évènement",
  },
  {
    to: "/admin/reservation",
    title: "Gestion des Réservations",
    tooltip: "Voir les réservations à venir",
  },
];

function TooltipWrapper({ children, title }) {
  return (
    <Tooltip
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      title={title}
      placement="bottom"
    >
      {children}
    </Tooltip>
  );
}
function AdminHomePage() {
  return (
    <>
      <Helmet>
        <title>Admin - Accueil</title>
        <meta name="robots" content="noindex, nofollow, noarchive" />
      </Helmet>
      <AdminMain>
        <StyledBox>
          {adminLinks.map(({ to, title, tooltip }) => (
            <TooltipWrapper key={to} title={tooltip}>
              <ButtonLink to={to}>{title}</ButtonLink>
            </TooltipWrapper>
          ))}
        </StyledBox>
      </AdminMain>
    </>
  );
}

export default AdminHomePage;
