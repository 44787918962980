import { Helmet } from "react-helmet-async";

function EventSEOMetadata() {
  return (
    <Helmet>
      <title>Événements et Concerts | Maëly's Café</title>
      <meta
        name="description"
        content="Découvrez les prochains événements et concerts au Maëly's Café. Profitez de musique live, de performances spéciales et de soirées inoubliables dans notre ambiance chaleureuse de bar."
      />
      <link rel="canonical" href="https://www.maelys-cafe.fr/events" />
      <meta name="robots" content="index, follow" />
      <meta
        property="og:title"
        content="Événements et Concerts au Maëly's Café"
      />
      <meta
        property="og:description"
        content="Musique live, performances spéciales et soirées inoubliables au Maëly's Café. Consultez notre calendrier d'événements."
      />
      <meta property="og:url" content="https://www.maelys-cafe.fr/events" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Événements et Concerts au Maëly's Café"
      />
      <meta
        name="twitter:description"
        content="Découvrez notre programmation d'événements et de concerts au Maëly's Café."
      />
    </Helmet>
  );
}

export default EventSEOMetadata;
