import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import BookingForm from "../../../UserComponents/BookingForm/CreateResa";
import { getAllEvents } from "../../../../utils/requests/eventRequest";
import "./nav.scss";

function Nav() {
  const [openModal, setOpenModal] = useState(false);
  const [events, setEvents] = useState();

  const fetchEvents = async () => {
    const response = await getAllEvents();
    setEvents(response.data);
  };

  const handleClickResa = async () => {
    await fetchEvents();
    setOpenModal(true);
  };

  return (
    <nav className="nav__container">
      <NavLink
        to="/"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Accueil</p>
      </NavLink>
      <NavLink
        to="/drinks"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Nos Boissons</p>
      </NavLink>
      <NavLink
        to="/events"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Evènements et Concerts</p>
      </NavLink>
      <NavLink
        to="/contact"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Nous Contacter</p>
      </NavLink>
      <Button
        sx={{
          mt: "2rem",
          backgroundColor: "#FFFDDE",
          color: "#333333",
          border: "none",
          borderRadius: "10px",
          padding: "1rem 2rem",
          boxShadow:
            "0px 0px 6px rgba(255,253,231,0.6), inset 0px 0px 6px rgba(255,253,231,0.6), 0px 0px 16px rgba(255,253,231,0.4), inset 0px 0px 16px rgba(255,253,231,0.4), 0px 0px 32px rgba(255,253,231,0.2), inset 0px 0px 32px rgba(255,253,231,0.2)",
        }}
        onClick={handleClickResa}
      >
        Réserver en Ligne
      </Button>
      <BookingForm
        open={openModal}
        events={events}
        onClose={() => setOpenModal(false)}
      />
    </nav>
  );
}
export default Nav;
