import { Modal, Box, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actionDeleteMeal } from "../../../redux/actions";
import Button from "../../common/Buttons/Button/Button";

import "./modal.scss";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  backgroundColor: "rgba(94, 94, 94, 0.865)",
  backdropFilter: "blur(2px)",
  color: "#fff",
  border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  padding: "1rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "1rem",
  },
}));
function CreatMealModal({ openModal, closeModal, modalTitle }) {
  const dispatch = useDispatch();

  const { title } = useSelector((state) => state.meal);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actionDeleteMeal());
    closeModal();
  };

  return (
    <Modal open={openModal} onClose={closeModal}>
      <StyledBox>
        <Typography id="modal__title" variant="h4" sx={{ textAlign: "center" }}>
          {modalTitle} &quot;
          <span style={{ fontWeight: "bold" }}>{title}</span>
          &quot; ?
        </Typography>
        <form className="modal__form" onSubmit={handleSubmit}>
          <div className="modal__form-button">
            <Button
              classname="normal-button cancel modal__form-button-button"
              type="button"
              name="Annuler"
              onClick={closeModal}
            />
            <Button
              classname="normal-button validate modal__form-button-button"
              type="submit"
              name="Supprimer"
            />
          </div>
        </form>
      </StyledBox>
    </Modal>
  );
}
export default CreatMealModal;
