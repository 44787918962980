import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1rem",
  },
}));

function TableHeader({ isMobile }) {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell align="center">Nom</StyledTableCell>
        {!isMobile && (
          <>
            <StyledTableCell align="center">Email</StyledTableCell>
            <StyledTableCell align="center">Téléphone</StyledTableCell>
          </>
        )}
        <StyledTableCell align="center">Nombre de personnes</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

function ReservationRow({ row, isMobile }) {
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "& .MuiTableCell": {
          fontSize: "1rem",
        },
      }}
    >
      <StyledTableCell align="center">
        {row.firstname} {row.lastname}
      </StyledTableCell>
      {!isMobile && (
        <>
          <StyledTableCell align="center">{row.email}</StyledTableCell>
          <StyledTableCell align="center">{row.phone}</StyledTableCell>
        </>
      )}
      <StyledTableCell align="center">{row.seat} personnes</StyledTableCell>
    </TableRow>
  );
}

function ReservationTable({ reservations, isMobile }) {
  return (
    <Table sx={{ width: "100%" }} size="small">
      <TableHeader isMobile={isMobile} />
      <TableBody sx={{ fontSize: "0.5rem" }}>
        {reservations?.map((row) => (
          <ReservationRow key={row.id} row={row} isMobile={isMobile} />
        ))}
      </TableBody>
    </Table>
  );
}

export default ReservationTable;
