import { ExpandLess } from "@mui/icons-material";
import { IconButton, useMediaQuery, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import useScrollToTop from "../../../hooks/useScrollToTop";
import SideBar from "../SideBar/SideBar";
import Footer from "../SideBar/Footer/Footer";
import Infos from "../SideBar/Infos/Infos";
import SnackbarComponent from "../SnackbarComponent";
import { actionCloseSnackbar } from "../../../redux/actions";
import useIsMobile from "../../../hooks/useIsMobile";

import "./main.scss";

function Main({ children }) {
  const { showTopBtn, goToTop } = useScrollToTop();
  const dispatch = useDispatch();
  const { open, severity, alertMessage } = useSelector(
    (state) => state.message
  );
  const isMobile = useIsMobile();

  const handleCloseSnackbar = () => dispatch(actionCloseSnackbar());

  const renderMobileComponents = () => (
    <>
      <Infos />
      <Footer />
    </>
  );

  const renderScrollToTopButton = () => (
    <IconButton
      sx={{
        color: "#000",
        position: "fixed",
        bottom: "40px",
        right: "10px",
        backgroundColor: "#FFF",
        zIndex: 100,
      }}
      onClick={goToTop}
    >
      <ExpandLess />
    </IconButton>
  );

  return (
    <Box
      sx={{
        display: isMobile ? "block" : "flex",
        width: "100vw",
      }}
    >
      {showTopBtn && isMobile && renderScrollToTopButton()}
      <SideBar />
      <Box className="main__container">{children}</Box>
      {isMobile && renderMobileComponents()}
      <SnackbarComponent
        open={open}
        onClose={handleCloseSnackbar}
        severity={severity}
        alertMessage={alertMessage}
      />
    </Box>
  );
}

export default Main;
