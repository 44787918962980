import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  Typography,
  CardMedia,
  Button,
} from "@mui/material";
import { ExpandMore, Edit } from "@mui/icons-material";
import moment from "moment";
import GenericIconButton from "../../../common/Buttons/IconButton/GenericIconButton";
import { getFrFormat, getFrTimeFormat } from "../../../../utils/dates/dates";

function EventAccordion({
  event,
  expanded,
  handleChange,
  reservations,
  isMobile,
  onEdit,
  onViewReservations,
}) {
  return (
    <Accordion
      expanded={expanded === event?.id}
      onChange={handleChange(event?.id)}
      key={event?.id}
      sx={{
        color: "#fffde7",
        backgroundColor: "transparent",
        "& .Mui-expanded": {
          backgroundColor: "rgba(94, 94, 94, 0.865)",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: "#fffde7" }} />}
        sx={{
          justifyContent: "space-between",
          "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
          },
          "& .Mui-expanded": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Stack direction={isMobile ? "column" : "row"} spacing={2}>
          <Typography variant="h3">{event?.title}</Typography>
          <Typography variant="h5">
            {moment(event?.date).format("DD/MM/YYYY")}
          </Typography>
        </Stack>
        <Box>
          <GenericIconButton
            tooltipText="Modifier l'événement"
            onClick={(e) => {
              if (expanded === event?.id) e.stopPropagation();
              onEdit(event);
            }}
            icon={<Edit />}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: "rgba(94, 94, 94, 0.865)" }}>
        <Stack direction={isMobile ? "column" : "row"} spacing={3}>
          {event?.photos?.[0]?.url ? (
            <CardMedia
              image={event.photos[0].url}
              sx={{ width: "150px", height: "200px" }}
              alt="Affiche de l'événement"
            />
          ) : (
            <Box
              sx={{
                width: "150px",
                height: "200px",
                backgroundColor: "gray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" color="white">
                Pas d'image
              </Typography>
            </Box>
          )}
          <Stack spacing={2}>
            <Typography>Date: {getFrFormat(event?.date)}</Typography>
            <Typography>Heure: {getFrTimeFormat(event?.date)}</Typography>
            {event?.description && (
              <Typography>Description: {event?.description}</Typography>
            )}
          </Stack>
          <Stack spacing={2}>
            <Typography>Réservations: {reservations?.length || 0}</Typography>
            <Typography>
              Places réservées :{" "}
              {reservations?.reduce((total, curr) => total + curr.seat, 0) || 0}
            </Typography>
            <Button
              disabled={!reservations?.length}
              onClick={() => onViewReservations(reservations)}
              sx={{
                color: "#fffde7",
                backgroundColor: "rgba(94, 94, 94, 0.865)",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.5)" },
              }}
            >
              Voir les réservations
            </Button>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export default EventAccordion;
