import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import Main from "../../common/Main/Main";
import MenuPart from "./MenuPart/MenuPart";
import CartePageSEOMetadata from "./CartePageSEOMetadata";
import useMenu from "../../../hooks/useMenu";
import "./cartepage.scss";

const SITE_URL = "https://www.maelys-cafe.fr";
function CartePage({ cardType }) {
  const isFoodCard = cardType === "food";
  const { card } = useMenu(isFoodCard ? 1 : 3);

  const sortedMenuParts = useMemo(() => {
    return card.menuparts
      ? card.menuparts.sort((a, b) => a.ordinal - b.ordinal)
      : [];
  }, [card.menuparts]);

  return (
    <>
      <CartePageSEOMetadata isFoodCard={isFoodCard} siteUrl={SITE_URL} />
      <Main>
        <div className="cartePage__container" key={card.id}>
          <Typography variant="h2">{card.name}</Typography>
          {sortedMenuParts.map((menupart, index) => (
            <MenuPart menupart={menupart} key={menupart.id} index={index} />
          ))}
        </div>
      </Main>
    </>
  );
}

export default React.memo(CartePage);
