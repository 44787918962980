import {
  Box,
  CardContent,
  CardMedia,
  Typography,
  styled,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { getFrFormat } from "../../../utils/dates/dates";
import BookingForm from "../BookingForm/CreateResa";

const boxStyles = {
  position: "relative",
  flexDirection: "column",
  padding: "0.5rem",
  width: "30%",
  color: "#fffde7",
  textAlign: "center",
  background:
    "linear-gradient(#0e0e0e 65%, #030303 66%) top 0.03em center / 100% 0.3em repeat-y;",
  margin: "1rem auto",
  border: `8px solid #fffde7`,
  borderRadius: "5px",
  boxShadow:
    "0px 0px 6px rgba(255,253,231,0.6), inset 0px 0px 6px rgba(255,253,231,0.6), 0px 0px 16px rgba(255,253,231,0.4), inset 0px 0px 16px rgba(255,253,231,0.4), 0px 0px 32px rgba(255,253,231,0.2), inset 0px 0px 32px rgba(255,253,231,0.2)",
};

const cardContentStyles = {
  display: "flex",
  "&:before": {
    content: `""`,
    display: "block",
    width: "60px",
    height: "20px",
    margin: "auto",
    background: " rgba(255, 255, 200, 0.6)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.4)",
    position: "absolute",
    top: "15px",
    left: "5px",
    transform: "rotate(-45deg)",
  },
  "&:after": {
    content: `""`,
    display: "block",
    width: "64px",
    height: "20px",
    margin: "auto",
    background: "rgba(255, 255, 200, 0.6)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.4)",
    position: "absolute",
    bottom: "15px",
    right: "0px",
    transform: "rotate(-35deg)",
  },
};

const buttonStyles = {
  position: "absolute",
  bottom: "10px",
  left: "25%",
  width: "50%",
  margin: "1rem 0",
  mt: "2rem",
  backgroundColor: "#FFFDDE",
  color: "#333333",
  border: "none",
  borderRadius: "10px",
  padding: "0.5rem 2rem",
  boxShadow:
    "0px 0px 6px rgba(255,253,231,0.6), inset 0px 0px 6px rgba(255,253,231,0.6), 0px 0px 16px rgba(255,253,231,0.4), inset 0px 0px 16px rgba(255,253,231,0.4), 0px 0px 32px rgba(255,253,231,0.2), inset 0px 0px 32px rgba(255,253,231,0.2)",
  "&.Mui-disabled": {
    backgroundColor: "#666",
    color: "#333333",
    border: "none",
    cursor: "not-allowed",
    boxShadow: "none",
  },
};

const StyledBox = styled(Box)(({ theme }) => ({
  ...boxStyles,
  [theme.breakpoints.down("md")]: {
    width: "90%",
    maxHeight: "650px",
  },
}));

const StyledCardContent = styled(CardContent)(() => cardContentStyles);

function ReservationButton({ complete, onClick }) {
  return (
    <Button
      sx={buttonStyles}
      onClick={onClick}
      variant="contained"
      color="primary"
      disabled={complete}
    >
      {complete ? "Complet" : "Réserver"}
    </Button>
  );
}

function ArticleEvent({ id, image, date, title, complete }) {
  const [open, setOpen] = useState(false);

  return (
    <StyledBox>
      <Typography variant="h5">{getFrFormat(date)}</Typography>
      <Typography variant="h4">{title}</Typography>
      <StyledCardContent>
        {image && (
          <CardMedia
            component="img"
            image={image.url}
            alt="green iguana"
            sx={{
              margin: "auto",
              objectFit: "contain",
              width: "95%",
              height: "95%",
            }}
          />
        )}
      </StyledCardContent>
      <Box sx={{ position: "relative", minHeight: "3rem" }} />
      <ReservationButton complete={complete} onClick={() => setOpen(true)} />
      <BookingForm
        open={open}
        onClose={() => setOpen(false)}
        eventDate={date}
        eventTitle={title}
        eventId={id}
      />
    </StyledBox>
  );
}

export default ArticleEvent;
