import { Stack, useMediaQuery } from "@mui/material";
import ArticleEvent from "./ArticleEvent";
import useIsMobile from "../../../hooks/useIsMobile";

function EventList({ events }) {
  const isMobile = useIsMobile();

  return (
    <Stack
      sx={{
        width: "100%",
        flexDirection: isMobile ? "column" : "row",
        flexWrap: "wrap",
      }}
    >
      {events.map((item) => (
        <ArticleEvent {...item} image={item.photos?.[0]} key={item.id} />
      ))}
    </Stack>
  );
}

export default EventList;
