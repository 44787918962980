import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionOpenSnackbar } from "../redux/actions";
import { getReservationsByWeek } from "../utils/requests/reservationRequest";

export const useReservations = () => {
  const dispatch = useDispatch();
  const [reservations, setReservations] = useState([]);

  const getWeekReservations = async () => {
    try {
      const response = await getReservationsByWeek();
      if (response.status === 200) {
        setReservations(response.data);
      }
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          message: "Erreur lors de la récupération des réservations",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    getWeekReservations();
  }, []);

  return reservations;
};
