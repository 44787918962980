import { Box, CardMedia, styled } from "@mui/material";

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  border: `8px solid rgba(0, 0, 0, 0.9)`,
  borderRadius: "10px",
  boxShadow: "0px 22px 6px -7px rgba(0, 0, 0, 0.74)",
  width: theme.breakpoints.down("md") ? "50%" : "20%",
}));

function SliderImage({ src, title, isMobile, hideOnMobile }) {
  return (
    <StyledCardMedia
      image={src}
      title={title}
      sx={{ display: hideOnMobile && isMobile ? "none" : "block" }}
    />
  );
}

export function ImageSlider({ isMobile }) {
  return (
    <Box
      sx={{
        margin: "2rem 0.5rem",
        display: "flex",
        justifyContent: isMobile ? "space-around" : "space-between",
        height: "20em",
        flexWrap: isMobile ? "wrap" : "nowrap",
      }}
    >
      <SliderImage
        src="/slider/IMG_5408.webp"
        title="pub"
        isMobile={isMobile}
        hideOnMobile
      />
      <SliderImage
        src="/slider/IMG_5407.webp"
        title="pub"
        isMobile={isMobile}
      />
      <SliderImage
        src="/slider/IMG_5411.webp"
        title="pub"
        isMobile={isMobile}
        hideOnMobile
      />
      <SliderImage
        src="/slider/IMG_5410.webp"
        title="pub"
        isMobile={isMobile}
      />
    </Box>
  );
}
