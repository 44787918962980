import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./notFoundPage.scss";

function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>Page non trouvée | Maëly's Café</title>
        <meta
          name="description"
          content="Oups ! La page que vous recherchez n'existe pas. Retournez au comptoir du Maëly's Café, votre bar convivial à Six-Fours-les-Plages, pour découvrir notre carte et nos événements."
        />
        <meta name="robots" content="noindex, follow" />
        <link rel="canonical" href="https://www.maelys-cafe.fr/404" />
        <meta property="og:title" content="Page non trouvée | Maëly's Café" />
        <meta
          property="og:description"
          content="Oups ! La page que vous recherchez n'existe pas. Retournez au comptoir du Maëly's Café pour découvrir notre carte et nos événements."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.maelys-cafe.fr/404" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Page non trouvée | Maëly's Café" />
        <meta
          name="twitter:description"
          content="Oups ! La page que vous recherchez n'existe pas. Retournez au comptoir du Maëly's Café pour découvrir notre carte et nos événements."
        />
      </Helmet>
      <div className="notFoundPage">
        <div className="notFoundPage__container">
          <h1 className="notFoundPage__title">
            Il n&apos;y a rien à boire et à manger ici ...
          </h1>
          <Link to="/" className="notFoundPage__link">
            Retourne au comptoir
          </Link>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
