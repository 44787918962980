import { Box, Typography, Tooltip } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { NavLink, Outlet } from "react-router-dom";
import AdminMain from "../AdminMain/AdminMain";

function MenuPage() {
  return (
    <>
      <Helmet>
        <title>Admin - Menus</title>
        <meta name="robots" content="noindex, nofollow, noarchive" />
      </Helmet>
      <AdminMain>
        <Box>
          <Typography variant="h2">Gestion des Boissons</Typography>
          <Outlet />
        </Box>
      </AdminMain>
    </>
  );
}
export default MenuPage;
